
import { Component, Mixins } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import Pda from '@/component/pda.vue'

// api
import {
    queryFactory,
    addFactory,
    updateFactory,
    delFactory
} from '@/api/factory/factory';

// 组件
import CustomModal from '@/component/custom-modal.vue';

@Component({
    name: 'FactoryManagement',
    components: {
        CustomModal,
        Pda
    },
})
export default class FactoryManagement extends Mixins(Validate) {
    is_loading: boolean = false;
    next_page: number = 1;
    form_item_layout: any = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    };
    factory_table: any = {
        detail: [],
        current: 1,
        last: 1,
        limit: 1,
        total: 10,
    };
    seach: any = {
        name: '',
        receiver: '',
        receiver_mobile: ''
    }
    // 添加工厂弹窗
    add_Modal: any = {
        title: '',
        visible: false,
        is_loading: false,
    };
    form: any = {
        factory_name: '',
        factory_address: '',
        factory_receiver: '',
        factory_receiver_mobile: '',
    };

    // 新增编辑校验
    checkTel(value: string) {
        return value.length===11;
        // const isMob: any = /^((\+?86)|(\(\+86\)))?(13[0123456789][0-9]{8}|15[0123456789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$/;
        // if (isMob.test(value)) {
        //     return true;
        // } else {
        //     return false;
        // }
    }
    validator_list: any[] = [
        {
            field: 'factory_name',
            message: '请填写工厂名称',
        },
        {
            field: 'factory_address',
            message: '请填写工厂地址'
        },
        {
            field: 'factory_receiver',
            message: '请填写收货人',
        },
        {
            field: 'factory_receiver_mobile',
            message: '请填写正确的11位手机号码',
            validate: (value: string): boolean => {
                if (value === '' || this.checkTel(value)) {
                    return !!value;
                } else {
                    return false;
                }

            },
        }
    ];
    validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);

    // 确认删除弹窗
    tips_modal: any = {
        title: '',
        visible: false,
        content: [],
        data: {},
        is_loading: false,
    };
    // 删除失败弹窗
    fail_modal: any = {
        title: '抱歉，因以下原因分类删除失败',
        visible: false,
        content: [],
    };
    pdaData: any = {
        type: 1,
        id: ""
    }
    pdaVisible: boolean = false
    pda(value: any) {
        this.pdaData.id = value.id
        this.pdaVisible = true
    }
    onTableChange(pagination: any) {
        this.next_page = pagination.current;
        this.fetchFilterWareList(this.next_page);
    }
    //分页
    paginationOption(data: any) {
        return {
            current: +data.current || 0,
            total: data.total || 0,
            pageSize: data.limit || 0,
            showTotal: () => `共${data.total}条，每页${data.limit}条`,
        };
    }
    // 获取商品列表
    async fetchWareList(current: number = 1) {
        this.factory_table = await queryFactory(current);
    }
    // 搜索
    async query() {
        await this.fetchFilterWareList();
    }
    @changeLoading(['is_loading'])
    async fetchFilterWareList(current: number = 1) {
        const send_data: any = {
            name: this.seach.name,
            receiver: this.seach.receiver,
            receiver_mobile: this.seach.receiver_mobile
        };
        for (const key in send_data) {
            if (send_data[key] === '') delete send_data[key];
        }

        this.factory_table = await queryFactory(current, send_data);
    }

    // 搜索清空重置
    async resetFilter() {
        this.seach = {
            name: '',
            receiver: '',
            receiver_mobile: ''
        };
        this.fetchWareList();
        this.query()
    }

    // 新增/编辑 弹窗
    add(type: number, title: string, record: any) {
        this.add_Modal.visible = true;
        this.add_Modal.title = title + '工厂';
        if (type == 2) {
            this.form = {
                id: record.id,
                factory_name: record.name,
                factory_address: record.address,
                factory_receiver: record.receiver,
                factory_receiver_mobile: record.receiver_mobile
            }
        }
    }

    // 新增/编辑 提交
    @changeLoading(['is_loading'])
    async addOk() {
        // 校验
        if (!this.validateCommit()) {
            return;
        }
        const data: any = {
            name: this.form.factory_name,
            address: this.form.factory_address,
            receiver: this.form.factory_receiver,
            receiver_mobile: this.form.factory_receiver_mobile
        }
        if (this.form.id == undefined) {
            this.add_Modal.is_loading = true;
            const res: any = await addFactory(data);
            this.add_Modal.is_loading = false;
            if (res.status !== 200) {
                this.$message.error((res as any).message);
                return;
            }
            this.$message.success('添加成功');
        } else {
            this.add_Modal.is_loading = true;
            const res = await updateFactory(data, this.form.id);
            this.add_Modal.is_loading = false;
            if (res.status !== 200) {
                this.$message.error((res as any).message);
                return;
            }
            this.$message.success('编辑成功');
        }
        this.clearModal();
        await this.fetchWareList();
    }
    // 新增/编辑 取消
    addCancel() {
        this.clearModal();
    }
    // 清空模态框数据
    clearModal() {
        // 清除校验信息
        this.add_Modal = {
            title: '',
            visible: false,
            is_loading: false,
        }
        this.form = {
            factory_name: '',
            factory_address: '',
            factory_receiver: '',
            factory_receiver_mobile: '',
        }
        this.$nextTick(() => {
            this.resetValidatorStatus();
        });
    }
    // 点击删除
    onDel(record: any) {
        this.tips_modal.title = '删除工厂'
        this.tips_modal.content = ['确认要删除此工厂信息吗？']
        this.tips_modal.visible = true;
        this.tips_modal.data = record;
    }
    // 删除
    @changeLoading(['is_loading'])
    async del() {
        this.tips_modal.is_loading = true;
        const res: any = await delFactory(this.tips_modal.data.id);
        this.tips_modal.is_loading = false;
        this.tips_modal.visible = false;
        if (res.status !== 200) {
            this.fail_modal.content = [...(res as any).message.split('\n')];
            this.fail_modal.visible = true;
            return;
        }
        await this.fetchWareList();
        this.$message.success('删除成功');
    }

    @changeLoading(['is_loading'])
    async created() {
        await this.fetchWareList();
    }
}
